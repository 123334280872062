import IncomingForecast from '@/domain/IncomingForecast';
import IncomingForecastApiService from './api/IncomingForecastApiService';
import ImportErrorService from './ImportErrorService';
import { getTranslation, getTitleCaseTranslation } from './TranslationService';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';

export default class IncomingForecastService extends ImportErrorService<IncomingForecast> {
    private apiService: IncomingForecastApiService = new IncomingForecastApiService();

    private readonly revalidationSuccessMsg = getTranslation('core.common.demandForecastHaveBeenQueuedUpForCreationAndShouldBeAvailableInTheSystemShortly');

    public async getAllFailed(): Promise<IncomingForecast[]> {
        const response = await this.apiService.getFailedIncomingForecasts();

        return response.data;
    }

    public async getAllFailedCount(): Promise<number> {
        const count = await this.apiService.getFailedIncomingForecastsCount();

        return count;
    }

    public async rejectFailed(id: number): Promise<number> {
        const response = await this.apiService.rejectIncomingForecast(id);

        if (response.success) {
            this.notification.showSuccess(getTranslation('core.common.rejectedDemandForecast'));
        }

        return response.data;
    }

    public async revalidateAllFailed(): Promise<number> {
        const response = await this.apiService.revalidateAllIncomingForecasts();

        return this.getRevalidateAllResult(response, this.revalidationSuccessMsg, getTranslation('core.validation.allItemsContainOneOrMoreValidationErrorsThatNeedAddressing'));
    }

    public async revalidateFailed(id: number): Promise<string[]> {
        const response = await this.apiService.revalidateIncomingForecast(id);

        return this.getRevalidateResult(response, this.revalidationSuccessMsg);
    }

    public async getIncomingForecast(id: number): Promise<IncomingForecast> {
        const response = await this.apiService.getIncomingForecast(id);
        return response.data;
    }

    public async updateIncomingForecast(forecast: IncomingForecast): Promise<DataAccessResponse<boolean>> {
        const response = await this.apiService.updateIncomingForecast(forecast);

        if (response.success) {
            this.notification.showSuccess(getTitleCaseTranslation('core.common.updatedDemandForecast'));
        }

        return response;
    }
}
