import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass, DataListAccessResponseClass } from './dataAccessResponse';
import IncomingForecast from '@/domain/IncomingForecast';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';

export default class IncomingForecastApiService {
    public async getFailedIncomingForecasts(): Promise<DataAccessResponse<IncomingForecast[]>> {
        const response: AxiosResponse<DataAccessResponse<IncomingForecast[]>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/DemandForecast/failed`,
        });

        return new DataListAccessResponseClass<IncomingForecast>(response, IncomingForecast).response;
    }

    public async getFailedIncomingForecastsCount(): Promise<number> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/DemandForecast/failed-count`,
        });

        return response.data.data;  
    }

    public async rejectIncomingForecast(id: number): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/DemandForecast/failed-rejection/${id}`,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async revalidateAllIncomingForecasts(): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/DemandForecast/failed-revalidation`,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async revalidateIncomingForecast(id: number): Promise<DataAccessResponse<string[]>> {
        const response: AxiosResponse<DataAccessResponse<string[]>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/DemandForecast/failed-revalidation/${id}`,
        });

        return new DataAccessResponseClass<string[]>(response).response;
    }

    public async getIncomingForecast(id: number): Promise<DataAccessResponse<IncomingForecast>> {
        const response: AxiosResponse<DataAccessResponse<IncomingForecast>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/DemandForecast/${id}`,
        });

        return new DataAccessResponseClass<IncomingForecast>(response, IncomingForecast).response;
    }

    public async updateIncomingForecast(forecast: IncomingForecast): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/DemandForecast/update`,
            data: forecast,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }
}
