import IncomingForecastStatus from './enums/IncomingForecastStatus';

export default class IncomingForecastLine {
    public id: number;

    public incomingForecastId: number;

    public itemNumber: string;

    public productionPartNumber: string;

    public quantity: number;

    public status: IncomingForecastStatus;

    public createdOn: Date;

    constructor(init?: Partial<IncomingForecastLine>) {
        this.id = init?.id || 0;
        this.incomingForecastId = init?.incomingForecastId || 0;
        this.itemNumber = init?.itemNumber || '';
        this.productionPartNumber = init?.productionPartNumber || '';
        this.quantity = init?.quantity || 0;
        this.status = init?.status || IncomingForecastStatus.Pending;
        this.createdOn = init?.createdOn ? new Date(init.createdOn) : new Date();
    }
}
