
import {
    computed, defineComponent, onMounted, reactive,
} from 'vue';
import { useRouter } from 'vue-router';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import Location from '@/domain/Location';
import LocationService from '@/services/LocationService';
import IncomingForecastService from '@/services/IncomingForecastService';
import IncomingForecast from '@/domain/IncomingForecast';
import IncomingForecastLine from '@/domain/IncomingForecastLine';
import ProductionPart from '@/domain/ProductionPart';
import Carrier from '@/domain/Carrier';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import useStringFormatter from '@/composable/useStringFormatter';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import Item from '@/domain/Item';
import ItemService from '@/services/ItemService';
import ProductionPartService from '@/services/ProductionPartService';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import resetProp from '@/functions/object';

type State = {
    originalIncomingForecast: IncomingForecast | undefined;
    incomingForecast: IncomingForecast | undefined;
    useItemNumber: boolean;
    locations: Array<Location>;
    carriers: Array<Carrier>;
    items: Array<Item>;
    productionParts: Array<ProductionPart>;
    loading: boolean;
    saving: boolean;
};

export default defineComponent({
    name: 'master-data-import-errors-idf-update',
    components: {
        BRow,
        BCol,
        DropdownAutocompleteSingleSelect,
        SmartTrakFooter,
        BSpinner,
        TextInput,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const locationService = new LocationService();
        const incomingForecastService = new IncomingForecastService();
        const itemService = new ItemService();
        const productionPartService = new ProductionPartService();

        const { titleCase } = useStringFormatter();
        const router = useRouter();

        const state = reactive<State>({
            originalIncomingForecast: undefined,
            incomingForecast: undefined,
            useItemNumber: true,
            locations: [],
            carriers: [],
            items: [],
            productionParts: [],
            loading: false,
            saving: false,
        });

        const pageTitle = computed(() => getTitleCaseTranslation('core.button.updateData') + (state.incomingForecast ? ` (${state.incomingForecast.partnerReferenceNumber})` : ''));

        const itemTableFields = computed(
            (): Array<BTableField<IncomingForecastLine>> => [
                {
                    key: state.useItemNumber ? 'itemNumber' : 'productionPartNumber',
                    label: getTitleCaseTranslation(state.useItemNumber ? 'core.domain.itemNumber' : 'core.domain.partNumber'),
                    ignoreSort: true,
                },
                {
                    key: 'quantity',
                    label: getTitleCaseTranslation('core.domain.quantity'),
                    ignoreSort: true,
                },
            ],
        );

        function locationExists(locationNumber: string | undefined) {
            return !!(locationNumber && state.locations.find((x) => x.locationNumber?.toLowerCase() === locationNumber?.toLowerCase()));
        }

        function itemExists(itemNumber: string | undefined) {
            return !!(itemNumber && state.items.find((x) => x.customerItemNumber?.toLowerCase() === itemNumber?.toLowerCase()));
        }

        function partExists(partNumber: string | undefined) {
            return !!(partNumber && state.productionParts.find((x) => x.number?.toLowerCase() === partNumber?.toLowerCase()));
        }

        onMounted(async () => {
            state.loading = true;

            const [locationsResponse, itemsResponse, productionPartsResponse, forecastReponse] = await Promise.all([
                locationService.getAllLocations(),
                itemService.getAllItems(),
                productionPartService.getAllProductionParts(),
                incomingForecastService.getIncomingForecast(parseInt(props.id, 10)),
            ]);

            if (locationsResponse.success) {
                state.locations = locationsResponse.locations.filter((x) => x.locationNumber);
            }
            if (itemsResponse.success) {
                state.items = itemsResponse.items.filter((x) => x.customerItemNumber);
            }
            if (productionPartsResponse.parts) {
                state.productionParts = productionPartsResponse.parts.filter((x) => x.number);
            }

            if (forecastReponse) {
                // store original values for displaying in labels
                state.originalIncomingForecast = new IncomingForecast(forecastReponse);

                state.incomingForecast = forecastReponse;
                // if any items on the forecast have an item number, assume we are using items not production parts
                state.useItemNumber = state.incomingForecast.incomingForecastLines.some((x) => x.itemNumber);

                // clear out any values that are invalid
                if (!locationExists(state.incomingForecast.fromLocation)) {
                    state.incomingForecast.fromLocation = '';
                }
                if (!locationExists(state.incomingForecast.toLocation)) {
                    state.incomingForecast.toLocation = '';
                }
                state.incomingForecast.incomingForecastLines.forEach((x) => {
                    if (x.itemNumber && !itemExists(x.itemNumber)) {
                        x.itemNumber = '';
                    }
                    if (x.productionPartNumber && !partExists(x.productionPartNumber)) {
                        x.productionPartNumber = '';
                    }
                });
            }

            state.loading = false;
        });

        function selectFromLocation(location: Location) {
            if (state.incomingForecast) {
                state.incomingForecast.fromLocation = location?.locationNumber ?? '';
            }
        }

        function selectToLocation(location: Location) {
            if (state.incomingForecast) {
                state.incomingForecast.toLocation = location?.locationNumber ?? '';
            }
        }

        function findOriginalLine(lineId: number) {
            return state.originalIncomingForecast?.incomingForecastLines?.find((x: IncomingForecastLine) => x.id === lineId);
        }

        function findOriginalItemNumber(lineId: number) {
            return findOriginalLine(lineId)?.itemNumber;
        }

        function findOriginalPartNumber(lineId: number) {
            return findOriginalLine(lineId)?.productionPartNumber;
        }

        function selectItem(incomingForecastItem: IncomingForecastLine, item: Item) {
            if (!state.incomingForecast || !incomingForecastItem) {
                return;
            }

            incomingForecastItem.itemNumber = item?.customerItemNumber ?? '';
            incomingForecastItem.productionPartNumber = '';
        }

        function selectPart(incomingForecastItem: IncomingForecastLine, part: ProductionPart) {
            if (!state.incomingForecast || !incomingForecastItem) {
                return;
            }

            incomingForecastItem.productionPartNumber = part?.number ?? '';
            incomingForecastItem.itemNumber = '';
        }

        function clearItem(incomingForecastItem: IncomingForecastLine) {
            incomingForecastItem.itemNumber = '';
            incomingForecastItem.productionPartNumber = '';
        }

        function goToIncomingForecastList() {
            router.push({ name: MasterDataRouteTypes.IMPORT_ERRORS.IDF });
        }

        async function save() {
            if (!state.incomingForecast) {
                return;
            }

            // reset any (editable) empty fields to the original value
            ['fromLocation', 'toLocation', 'carrier'].forEach((x) => resetProp(x, state.incomingForecast, state.originalIncomingForecast));
            state.incomingForecast.incomingForecastLines.forEach((item, index) => {
                ['itemNumber', 'productionPartNumber'].forEach((x) => resetProp(x, item, state.originalIncomingForecast?.incomingForecastLines[index]));
            });

            state.saving = true;
            const response = await incomingForecastService.updateIncomingForecast(state.incomingForecast);
            if (response?.success) {
                goToIncomingForecastList();
            }
            state.saving = false;
        }

        return {
            state,
            pageTitle,
            selectToLocation,
            selectFromLocation,
            titleCase,
            getTitleCaseTranslation,
            getTranslation,
            goToIncomingForecastList,
            save,
            itemTableFields,
            selectItem,
            selectPart,
            clearItem,
            locationExists,
            itemExists,
            partExists,
            findOriginalItemNumber,
            findOriginalPartNumber,
        };
    },
});
