import IncomingForecastStatus from './enums/IncomingForecastStatus';
import ImportError from './ImportError';
import IncomingForecastLine from './IncomingForecastLine';

export default class IncomingForecast implements ImportError {
    public id: number;

    public fromLocation: string;

    public toLocation: string;

    public plannedDeliveryDate: Date;

    public partnerReferenceNumber: string;

    public createdOn: Date;

    public changedOn: Date;

    public incomingForecastLines: IncomingForecastLine[];

    constructor(init?: Partial<IncomingForecast>) {
        this.id = init?.id || 0;
        this.fromLocation = init?.fromLocation || '';
        this.toLocation = init?.toLocation || '';
        this.plannedDeliveryDate = init?.plannedDeliveryDate ? new Date(init.plannedDeliveryDate) : new Date();
        this.partnerReferenceNumber = init?.partnerReferenceNumber || '';
        this.createdOn = init?.createdOn ? new Date(init.createdOn) : new Date();
        this.changedOn = init?.changedOn ? new Date(init.changedOn) : new Date();
        this.incomingForecastLines = init?.incomingForecastLines?.map((x) => new IncomingForecastLine(x)) || [];
    }

    public get failedIncomingForecastLines(): IncomingForecastLine[] {
        return this.incomingForecastLines.filter((item) => item.status === IncomingForecastStatus.Failed);
    }
}
